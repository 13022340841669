import React from 'react'
import '../CompaignLandingPage/BLanding.css'
import SpaceTerms from './SpaceTerms';
import EffortlessListing from './EffortlessListing';
import HomeClientsTestimonials from '../Home/HomeClientsTestimonials/HomeClientsTestimonials';
import Reliability from './Reliability';
import { Link } from 'react-router-dom';

const BLandingPage = () => {
  return (
    <div className='mx-auto' style={{maxWidth:'1600px' , overflow:'hidden' , marginTop:'120px'}}>

        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div className='justify-content-center align-items-center px-5' 
                style={{
                    backgroundColor: '#FFCC00',
                    // border: '0.4px solid #B9B9B9',
                    borderRadius: '100px',
                    padding: '5px 10px',
                    display: 'flex',           
                    justifyContent: 'center', 
                    alignItems: 'center',
                    textAlign: 'center', 
                }}>
                <p className='landing-text1 a-font blueTextColr' style={{ margin: 0 }}>
                    A perfect, secure, free-of-cost, and ALL-in-ONE platform for STORAGE OWNERS
                </p>
            </div>
        </div>

        <div className='mt-5'>
            <p className='landing-head a-font blueTextColr'>FindxStorage's 
                <span className='yellowtext'> "Smart Storage Platform" <br></br></span>
                <span> Connects Storage owners with <br></br> customers</span>
                <span className='yellowtext'> in Minutes</span>
            </p>
            <p className='a-font blueTextColr landing-text1'>Without any cost, long Waits, complicated listing processes, or endless paperwork! </p>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-5 mt-5'>
        <div className='d-flex justify-content-center'>
        <Link to={'/sign-up-storage-provider'}>
            <button
            className='a-font blueTextColr p-2 px-4'
            style={{
                backgroundColor:'#ffcc00',
                border:'4px solid #FFCC00',
                borderRadius:'10px',
                fontWeight:700,
            }}
            >List Your Storage Unit</button>
        </Link>
            </div>
            <p className='landing-head a-font blueTextColr text-uppercase mb-0'>For Free</p>
        </div>
        <div>
            <p className='landing-text1 a-font blueTextColr mt-3'
            style={{fontWeight:600}}
            >(So You Make Every Inch of Your Space Make Money for You)</p>
        </div>
        

        <div className='d-flex mx-auto position-relative' style={{
            width:'87%',
            marginTop:'80px',
        }}>
            <img src='/images/B2blanding1.png' alt='blandingpic1'
            style={{
                width:'937px',
                // position:'absolute',
                height:'759px',
                borderRadius:'22px',
                zIndex:10,
            }}></img>            
            <img src='/images/B2blanding2.png' alt='blandingpic2'
            style={{
                width:'556px',
                height:'688px',
                position:'absolute',
                borderRadius:'17px',
                zIndex:30,
                right:'0%',
                top:'25%',
                // left:'20%',
            }}
            ></img>            
        </div>


        <div className='d-flex flex-column justify-content-center' style={{marginTop:'180px'}}> 
            <p className='a-font blueTextColr landing-text2 mx-auto' style={{width:'70%'}}>
            Any storage owner can earn extra income by listing their empty spaces… 
            <span style={{fontWeight:700}}>
                Without worrying about inconsistent bookings, handling complicated contracts, or waiting months to find customers!</span>
            </p>
            <div className='mx-auto'>
                <Link to={'/sign-up-storage-provider'}>
                <button
                className='a-font blueTextColr p-2 px-4'
                style={{
                    backgroundColor:'#ffcc00',
                    border:'4px solid #FFCC00',
                    borderRadius:'10px',
                    fontWeight:700,
                }}
                >Let's Start</button>
            </Link>
                <div style={{
                    position:'relative',
                    left:'115%',
                    zIndex:30,
                    top:'-30px',
                }}  >
                <svg width="51" height="66" viewBox="0 0 51 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.4017 59.7473C32.1903 59.3768 30.9136 59.2701 29.6575 59.4343C45.8753 53.6094 50.0248 36.8437 39.9277 23.1404C30.9736 11.3703 14.489 7.62245 0.411249 9.49683C0.35923 9.50438 0.309354 9.52265 0.26477 9.55049C0.220187 9.57833 0.18187 9.61513 0.15226 9.65856C0.12265 9.70199 0.102384 9.75109 0.0927473 9.80276C0.0831104 9.85443 0.0843209 9.90754 0.0962893 9.95872C0.116362 10.046 0.166463 10.1234 0.237834 10.1775C0.309205 10.2316 0.397307 10.2589 0.486747 10.2546C13.8552 9.59818 29.2841 13.6136 37.2005 25.0138C44.8158 37.4895 40.6825 50.384 29.0919 58.5324C29.6891 56.7309 29.9011 54.8239 29.7143 52.9352C29.7111 52.8995 29.6957 52.866 29.6707 52.8404C29.6457 52.8148 29.6125 52.7986 29.5769 52.7947C29.5413 52.7907 29.5055 52.7991 29.4754 52.8186C29.4453 52.8381 29.4229 52.8673 29.4119 52.9014C28.6085 55.2338 27.8536 57.4333 26.4523 59.857C25.6043 61.3188 25.3317 61.9198 27.2285 61.4116C29.5322 60.7943 32.0572 60.1177 34.4359 60.6562C34.4836 60.6639 34.5325 60.6551 34.5745 60.6312C34.6165 60.6073 34.649 60.5697 34.6667 60.5248C34.6845 60.4799 34.6863 60.4302 34.672 60.3841C34.6576 60.338 34.6279 60.2981 34.5878 60.2712C34.215 60.0494 33.8167 59.8735 33.4017 59.7473Z" fill="#FFCC00"/>
                <path d="M6.57951 12.7654C14.3162 14.3373 21.8001 20.0684 23.4511 27.972C23.4574 28.0131 23.4488 28.055 23.427 28.0904C23.4051 28.1257 23.3714 28.1521 23.3318 28.1649C23.2923 28.1776 23.2495 28.1759 23.2111 28.1599C23.1728 28.144 23.1413 28.1149 23.1224 28.0779C19.8351 21.0643 13.4041 16.3213 6.4696 13.0882C6.43302 13.0692 6.40446 13.0377 6.38911 12.9994C6.37376 12.9611 6.37263 12.9186 6.38592 12.8796C6.39921 12.8406 6.42605 12.8076 6.46156 12.7866C6.49708 12.7657 6.53892 12.7581 6.57951 12.7654Z" fill="#FFCC00"/>
                <path d="M3.52072 12.2173C2.50757 12.2652 1.50198 12.0212 0.623451 11.5142C0.59045 11.4963 0.563348 11.4692 0.545418 11.4362C0.527488 11.4031 0.519496 11.3656 0.522405 11.3282C0.525313 11.2907 0.538998 11.2549 0.561809 11.2251C0.584621 11.1952 0.615582 11.1726 0.650959 11.16C1.17089 11.0076 1.72174 10.9945 2.24833 11.122C2.77493 11.2495 3.25878 11.5131 3.65142 11.8864C3.67782 11.9133 3.69585 11.9472 3.70335 11.9841C3.71084 12.0211 3.70747 12.0593 3.69363 12.0944C3.6798 12.1294 3.6561 12.1597 3.62541 12.1815C3.59471 12.2033 3.55835 12.2158 3.52072 12.2173Z" fill="#FFCC00"/>
                </svg>
                </div>
                
            </div>
            <p className='a-font blueTextColr landing-text1'>🙋‍♂️ 🙋‍♀️ Yes, I Want to Earn Effortlessly with My Empty Space!</p>
        </div>

        <SpaceTerms></SpaceTerms>
        <EffortlessListing></EffortlessListing>
        <p className='blueTextColr a-font landing-head'>
            Hear from those
            <span className='yellowtext'> who <br></br> chose us</span>.
        </p>
        <HomeClientsTestimonials></HomeClientsTestimonials>
        <Reliability></Reliability>
    </div>
  )
}

export default BLandingPage;
