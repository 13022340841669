import React from 'react'
import { PiHandPointingFill } from 'react-icons/pi'
import { Link } from "react-router-dom";

const EffortlessListing = () => {
  return (
    <div style={{marginTop:'120px'}}>
        <div className='mx-auto' style={{width:'83%'}}>
            <p className='landing-head a-font blueTextColr'>Our AI-powered platform handles
                    <span className='yellowtext'> bookings, payment, and customer management</span>
                    <span> while you earn passive income.</span>
            </p>
            <p className='blueTextColr landing-text1 a-font mt-5'>
                FindxStorage’s AI-Powered pricing system takes the guesswork out of your rates, 
                <span style={{fontWeight:700}}> automatically optimizing for maximum profit to ensure you earn what your space is truly worth.</span>
            </p>
        </div>

        

        <div className='d-flex flex-column gap-3 justify-content-center'>
            <div className='p-5 mx-auto' style={{
            backgroundColor:'#000034',
            width:'503px',
            height:'134px',
            borderRadius:'30px',
            display:'flex',
            alignItems:'center',
            marginTop:'160px',
            }}>

            <img src="/images/FindxStorage3.png" height={20} alt="Logo"
            className='img-fluid' />
            </div>

            <p className='landing-text3 a-font blueTextColr'>Makes listing storage spaces Effortless.</p>
            <p className='landing-text2 a-font blueTextColr'>Here's How</p>

            <div className='d-flex justify-content-center'>
            <svg width="70" height="64" viewBox="0 0 70 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.7429 57.3441C42.6723 54.096 43.7892 50.4034 45.2656 46.8985C45.2979 46.8248 45.3544 46.7651 45.4238 46.7314C45.4932 46.6977 45.5703 46.6925 45.6396 46.7169C45.7499 46.7579 45.8443 46.8338 45.9107 46.935C45.9771 47.0362 46.0125 47.1582 46.0125 47.2854C46.0131 48.9048 45.457 52.636 43.7496 54.4891C52.2451 45.268 70.0306 25.51 50.3974 20.4532C43.0472 17.9695 35.0936 16.9308 27.3407 15.6759C16.946 14.4384 7.61841 11.9295 19.2478 1.23136C19.3759 1.11468 19.535 1.04601 19.6983 1.03683C19.8616 1.02765 20.0192 1.07852 20.145 1.18093C20.1799 1.21041 20.208 1.24784 20.2276 1.29079C20.2472 1.33374 20.2577 1.38123 20.2586 1.43012C20.2595 1.47902 20.2506 1.52823 20.2326 1.57451C20.2146 1.6208 20.1879 1.66311 20.1542 1.69869C9.97592 12.5055 18.5793 12.3648 27.7753 12.8975C37.7628 13.6966 48.3574 14.0184 57.8292 18.0819C75.4232 26.8972 56.2399 49.565 44.2471 55.3037C46.3606 54.3975 49.8666 55.0769 50.7001 55.792C50.7983 55.8599 50.8708 55.9619 50.9064 56.0823C50.942 56.2027 50.9387 56.3348 50.8971 56.4584C50.8647 56.5368 50.8114 56.6045 50.7442 56.6523C50.6769 56.7002 50.599 56.726 50.5206 56.7263C47.7388 56.7247 44.9551 57.4553 42.4149 58.8536C40.4218 59.9015 40.0897 58.4399 40.7429 57.3441Z" fill="#FFCC00"/>
            <path d="M43.2893 23.1465C49.954 22.6212 56.5154 27.3379 54.2427 32.4507C54.2267 32.4845 54.2009 32.5123 54.1694 32.5297C54.1378 32.547 54.1024 32.5529 54.0688 32.5463C54.0352 32.5398 54.0054 32.5211 53.9842 32.4934C53.963 32.4658 53.9516 32.4306 53.9518 32.3937C53.9329 28.7159 50.1013 24.5934 43.2534 23.6253C43.1965 23.6209 43.1448 23.5933 43.1088 23.5481C43.0728 23.503 43.0553 23.4436 43.0599 23.3823C43.0645 23.321 43.0908 23.2624 43.1335 23.2186C43.1761 23.1747 43.2319 23.1489 43.2893 23.1465Z" fill="#FFCC00"/>
            <path d="M38.9916 23.0791C39.2552 23.2705 39.5567 23.3989 39.8771 23.4561C40.1974 23.5134 40.5297 23.4982 40.853 23.4116C40.9036 23.3963 40.9499 23.3661 40.9867 23.3247C41.0234 23.2833 41.049 23.2323 41.0604 23.1777C41.0719 23.1231 41.0687 23.0671 41.0514 23.0163C41.034 22.9655 41.0031 22.922 40.9623 22.8908C40.6994 22.6995 40.3985 22.5712 40.0788 22.5143C39.7591 22.4573 39.4274 22.4729 39.1049 22.5599C39.0544 22.5749 39.0081 22.6047 38.9712 22.6458C38.9344 22.6868 38.9085 22.7374 38.8967 22.7918C38.8848 22.8461 38.8874 22.902 38.9042 22.9528C38.921 23.0037 38.9513 23.0475 38.9916 23.0791Z" fill="#FFCC00"/>
            </svg>
            </div>

        </div>

        <div className='d-flex flex-column gap-3 mt-5 mx-auto' style={{maxWidth:'840px'}}>

            <div className='d-flex gap-4'>
                <div className='d-flex flex-column'>
                    <PiHandPointingFill style={{ transform: 'rotate(90deg)' }} size={40} fill='#ffcc00' />
                </div>

                <div className=''>
                    <p className='blueTextColr a-font landing-text2 text-start' style={{fontWeight:700}}
                    >Maximize Your Space's Potential:</p>
                    <p className='blueTextColr a-font landing-text1 text-start'>
                    From climate-controlled to outdoor, vehicle, and business storage units, we help you maximize every square foot of your space.
                    </p>
                </div>
            </div>

            <div className='d-flex gap-4'>
                <div className='d-flex flex-column'>
                    <PiHandPointingFill style={{ transform: 'rotate(90deg)' }} size={40} fill='#ffcc00' />
                </div>

                <div className=''>
                    <p className='blueTextColr a-font landing-text2 text-start' style={{fontWeight:700}}
                    >Built-In Security Features:</p>
                    <p className='blueTextColr a-font landing-text1 text-start'>
                    Our platform offers renters peace of mind with gated access, 24/7 surveillance, and on-site management at selected locations
                    </p>
                </div>
            </div>

            <div className='d-flex gap-4'>
                <div className='d-flex flex-column'>
                    <PiHandPointingFill style={{ transform: 'rotate(90deg)' }} size={40} fill='#ffcc00' />
                </div>

                <div className=''>
                    <p className='blueTextColr a-font landing-text2 text-start' style={{fontWeight:700}}
                    >Streamlined Access:</p>
                    <p className='blueTextColr a-font landing-text1 text-start'>
                    Our platform makes it convenient for renters by offering them options like drive-up access or flexible scheduling for storage and retrieval.
                    </p>
                </div>
            </div>

            <div className='d-flex gap-4'>
                <div className='d-flex flex-column'>
                    <PiHandPointingFill style={{ transform: 'rotate(90deg)' }} size={40} fill='#ffcc00' />
                </div>

                <div className=''>
                    <p className='blueTextColr a-font landing-text2 text-start' style={{fontWeight:700}}
                    >Hassle-Free Listing Process:</p>
                    <p className='blueTextColr a-font landing-text1 text-start'>
                    We help you list your space quickly and straightforward without any complicated contracts.
                    </p>
                </div>
            </div>

            <div className='d-flex gap-4'>
                <div className='d-flex flex-column'>
                    <PiHandPointingFill style={{ transform: 'rotate(90deg)' }} size={40} fill='#ffcc00' />
                </div>

                <div className=''>
                    <p className='blueTextColr a-font landing-text2 text-start' style={{fontWeight:700}}
                    >Guaranteed Payments:</p>
                    <p className='blueTextColr a-font landing-text1 text-start'>
                    Enjoy secure, reliable payments through our trusted system every time. No more worrying about late or missed payments.
                    </p>
                </div>
            </div>

            <div className='d-flex gap-4'>
                <div className='d-flex flex-column'>
                    <PiHandPointingFill style={{ transform: 'rotate(90deg)' }} size={40} fill='#ffcc00' />
                </div>

                <div className=''>
                    <p className='blueTextColr a-font landing-text2 text-start' style={{fontWeight:700}}
                    >Flexibility on Your Terms:</p>
                    <p className='blueTextColr a-font landing-text1 text-start'>
                    You decide the rental terms, pricing, and availability. With FindxStorage, you're in control of how your space is used.
                    </p>
                </div>
            </div>
        </div>


        <div className='mt-4'>
            <div className='d-flex justify-content-center'>
            <Link to={'/sign-up-storage-provider'}>
            <button
            className='a-font landing-text1 blueTextColr p-2 px-3'
            style={{
                backgroundColor:'#ffcc00',
                border:'4px solid #ffcc00',
                borderRadius:'10px',
                fontWeight:700,
            }}
            >List Your Storage Unit</button>
            </Link>
            </div>
            <p className='landing-head a-font blueTextColr text-uppercase mt-2'>For Free</p>
        </div>


    </div>
  )
}

export default EffortlessListing