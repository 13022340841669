import React from 'react'
import { Link } from "react-router-dom";

const Reliability = () => {
  return (
    <div className='' style={{marginTop:'120px'}}>
        <div className='mx-auto' style={{width:'75%'}}>
        <p className='landing-head yellowtext a-font'>Why FindxStorage
            <span className='blueTextColr'> Is the Most Reliable Storage Platform for Businesses…</span>
        </p>
        <p className='landing-text1 blueTextColr a-font'>FindxStorage isn’t just another typical storage platform…we’re built on experience, trust, and innovation to offer you the best storage listing solutions in the USA.</p>

        </div>

        <div className='d-flex gap-5' style={{marginTop:'120px'}}>
            <div className='d-flex flex-column gap-5'>
                <div className='d-flex flex-column align-items-center gap-2'>
                    <img src='/images/confuse.png' alt='reli'
                    className='img-fluid'
                    width={94}
                    height={94}
                    >
                    </img>
                    <p className='blueTextColr a-font landing-text2 mb-0 mt-1' style={{fontWeight:700}}>
                        AI-Powered Efficiency</p>
                    <p className='blueTextColr a-font landing-text1 mb-0'>Smart pricing, automated listings, and direct renter connections.</p>
                </div>

                <div className='d-flex flex-column align-items-center gap-2'>
                    <img src='/images/reliable3.png' alt='reli'
                    className='img-fluid'
                    width={94}
                    height={94}
                    >
                    </img>
                    <p className='blueTextColr a-font landing-text2 mb-0 mt-1' style={{fontWeight:700}}>
                    User-Focused Design</p>
                    <p className='blueTextColr a-font landing-text1 mb-0'>Easy navigation and seamless management.</p>
                </div>
            </div>

            <div
                className="flex items-center justify-center blueTextColr landing-text2"
                style={{
                width: '350px',
                height: '350px',
                borderRadius: '50%',
                backgroundColor: '#ffcc00',
                margin: '0 auto',
                fontWeight:700,
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                }}
            >
                Here's what makes us different
            </div>

            <div className='d-flex flex-column gap-5'>
                <div className='d-flex flex-column align-items-center gap-2'>
                    <img src='/images/reliable2.png' alt='reli'
                    className='img-fluid'
                    width={94}
                    height={94}
                    >
                    </img>
                    <p className='blueTextColr a-font landing-text2 mb-0 mt-1' style={{fontWeight:700}}>
                    Advanced Security Features</p>
                    <p className='blueTextColr a-font landing-text1 mb-0'>
                    Gated access, secure payments, and verified renters.
                    </p>
                </div>

                <div className='d-flex flex-column align-items-center gap-2'>
                    <img src='/images/reliable4.png' alt='reli'
                    className='img-fluid'
                    width={94}
                    height={94}
                    >
                    </img>
                    <p className='blueTextColr a-font landing-text2 mb-0 mt-1' style={{fontWeight:700}}>
                    Seamless Booking Process</p>
                    <p className='blueTextColr a-font landing-text1 mb-0'>Quick reservations with hassle-free payments.</p>
                </div>
            </div>

        </div>

        <div className='d-flex flex-column align-items-center gap-2'>
            <img src='/images/reliable5.png' alt='reli'
            className='img-fluid'
            width={94}
            height={94}
            >
            </img>
            <p className='blueTextColr a-font landing-text2 mb-0 mt-1' style={{fontWeight:700}}>
                24/7 Customer Support                                                                                                                                                                                                                       </p>
            <p className='blueTextColr a-font landing-text1 mb-0'>
                Expert assistance whenever you need it.</p>
        </div>


        <div className='w-full d-flex flex-column gap-4 justify-content-center mt-5' 
        style={{backgroundColor:'#000034' ,
            padding:'80px',
            paddingLeft: '200px',
            paddingRight:'200px',
        }}>
            <p className='text-white landing-head a-font' style={{fontWeight:600}}>
            Be one of those 
            <span className='yellowtext'> thousands of storage owners who trust FindxStorage </span>
            and have already listed their spaces with us for free.
            </p>

            <div className='mt-3 mx-auto'>
                <div className='mx-auto'>
                <Link to={'/sign-up-storage-provider'}>
                <button
                className='a-font landing-text1 blueTextColr p-2 px-3'
                style={{
                    backgroundColor:'#ffcc00',
                    border:'4px solid #ffcc00',
                    borderRadius:'10px',
                    fontWeight:700,
                }}
                >List Your Storage Unit</button>
                </Link>
                </div>
                <p className='mt-2 landing-head a-font text-white text-uppercase'>For Free</p>
            </div>

        </div>
    </div>
  )
}

export default Reliability