import React, { useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  MdLocationCity,
  MdDriveFileRenameOutline,
  MdOutlineDescription,
} from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IoIosAddCircle } from "react-icons/io";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import useImageUploader from "../../../../hooks/useImageUpload";
import AddUnitLocation from "./AddUnitLocation";
import { createStorageUnit } from "../../../../actions/storageUnitActions";
import AddUnitMap from "./AddUnitMap";
import { IoMdCloudUpload } from 'react-icons/io';
import { TbFileUpload } from "react-icons/tb";
import { IoCheckmark, IoToggle } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { FaMinus } from 'react-icons/fa';
import { FiMinus, FiPlus } from "react-icons/fi";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiClock } from "react-icons/hi2";
import { GoChevronDown } from "react-icons/go";
import { SlTrash } from "react-icons/sl";
import { LiaToggleOffSolid } from "react-icons/lia";
import { PiToggleLeftFill } from "react-icons/pi";

const AddUnitForm = ({ onLocationChange }) => {
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);
  const cloudName = "dxqfv1xa2";
  const { handleImageUpload, uploading } = useImageUploader(cloudName);
  const dispatch = useDispatch();

  const [uploadedImages, setUploadedImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedAccessDays, setSelectedAccessDays] = useState([]);
  const [timingstoggle, SetTimingstoggle] = useState(false);
  const [accesstimingstoggle, SetAccessTimingstoggle] = useState(false);

  const allDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const allDaysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const daysMap = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const urls = await handleImageUpload(files);
    const formattedImages = urls.map((url) => ({ image: url }));
    setUploadedImages((prevImages) => [...prevImages, ...formattedImages]);
  };

  const removeImage = (index) => {
    setUploadedImages((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
  };

  const getLocationData = (data, id, setFieldValue) => {
    if (!data) {
      setFieldValue(id, null);
      return;
    }

    onLocationChange(data);
    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.country || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };
    setFieldValue(id, locationData);
  };

  const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(":");
    const hour12 = hour % 12 || 12;
    const period = hour < 12 ? "AM" : "PM";
    return `${hour12}:${minute} ${period}`;
  };

  const initialValues = {
    name: "",
    location: null,
    address: "",
    starting_work_time: "",
    ending_work_time: "",
    starting_work_day: "",
    ending_work_day: "",
    starting_access_time: "",
    ending_access_time: "",
    starting_access_day: "",
    ending_access_day: "",
    description: "",
    facility_images: [],
    features: [{ features_text: "" }],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    location: Yup.object().required("Location is required"),
    address: Yup.string().required("Address is required"),
    starting_work_time: Yup.string().required("Starting Work Time is required"),
    ending_work_time: Yup.string().required("Ending Work Time is required"),
    starting_work_day: Yup.string().required("Starting Work Day is required"),
    ending_work_day: Yup.string().required("Ending Work Day is required"),
    starting_access_time: Yup.string().required("Starting Access Time is required"),
    ending_access_time: Yup.string().required("Ending Access Time is required"),
    starting_access_day: Yup.string().required("Starting Access Day is required"),
    ending_access_day: Yup.string().required("Ending Access Day is required"),
  });

  const handleFormSubmit = async (values, { setSubmitting }) => {
    values.storage_provider_ids = serviceProvider.id;
    values.facility_images = uploadedImages;
    values.working_hours = `${values.starting_work_day} - ${
      values.ending_work_day
    }  ${convertTo12HourFormat(values.starting_work_time)} - ${convertTo12HourFormat(
      values.ending_work_time,
    )}`;
    values.access_hours = `${values.starting_access_day} - ${
      values.ending_access_day
    }  ${convertTo12HourFormat(values.starting_access_time)} - ${convertTo12HourFormat(
      values.ending_access_time,
    )}`;
    
    try {
      await validationSchema.validate(values, { abortEarly: false});
      dispatch(createStorageUnit(values));
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      toast.error("Failed to create storage unit");
    }
  };

  const toggleDaySelection = (index, setFieldValue) => {
    const newSelectedDays = [...selectedDays];
    const isSelected = newSelectedDays.includes(index);
    SetTimingstoggle(false);
    
    if (isSelected) {
      const removeIndex = newSelectedDays.indexOf(index);
      newSelectedDays.splice(removeIndex);
    } else {
      newSelectedDays.push(index);
      newSelectedDays.sort((a, b) => a - b);
      
      const first = Math.min(...newSelectedDays);
      const last = Math.max(...newSelectedDays);
      for (let i = first; i <= last; i++) {
        if (!newSelectedDays.includes(i)) {
          newSelectedDays.push(i);
        }
      }
      newSelectedDays.sort((a, b) => a - b);
    }
  
    setSelectedDays(newSelectedDays);

    if (newSelectedDays.length > 0) {
      const startingDay = newSelectedDays[0];
      const endingDay = newSelectedDays[newSelectedDays.length - 1];
      setFieldValue("starting_work_day", daysMap[startingDay]);
      setFieldValue("ending_work_day", daysMap[endingDay]);
    } else {
      setFieldValue("starting_work_day", "");
      setFieldValue("ending_work_day", "");
    }
  };

  const toggleaccessDaySelection = (index, setFieldValue) => {
    const newSelectedDays = [...selectedAccessDays];
    const isSelected = newSelectedDays.includes(index);
    SetAccessTimingstoggle(false);
    
    if (isSelected) {
      const removeIndex = newSelectedDays.indexOf(index);
      newSelectedDays.splice(removeIndex);
    } else {
      newSelectedDays.push(index);
      newSelectedDays.sort((a, b) => a - b);
      
      const first = Math.min(...newSelectedDays);
      const last = Math.max(...newSelectedDays);
      for (let i = first; i <= last; i++) {
        if (!newSelectedDays.includes(i)) {
          newSelectedDays.push(i);
        }
      }
      newSelectedDays.sort((a, b) => a - b);
    }
  
    setSelectedAccessDays(newSelectedDays);

    if (newSelectedDays.length > 0) {
      const startingDay = newSelectedDays[0];
      const endingDay = newSelectedDays[newSelectedDays.length - 1];
      setFieldValue("starting_access_day", daysMap[startingDay]);
      setFieldValue("ending_access_day", daysMap[endingDay]);
    } else {
      setFieldValue("starting_access_day", "");
      setFieldValue("ending_access_day", "");
    }
  };

  const handleToggle = (setFieldValue) => {
    if (!timingstoggle) {
      setSelectedDays([0, 1, 2, 3, 4, 5, 6]);
      setFieldValue("starting_work_day", "SUN");
      setFieldValue("ending_work_day", "SAT");
      setFieldValue("starting_work_time", "00:00");
      setFieldValue("ending_work_time", "23:59");
    } else {
      setSelectedDays([]);
      setFieldValue("starting_work_day", "");
      setFieldValue("ending_work_day", "");
      setFieldValue("starting_work_time", "");
      setFieldValue("ending_work_time", "");
    }
    SetTimingstoggle(!timingstoggle);
  };

  const handleToggleaccess = (setFieldValue) => {
    if (!accesstimingstoggle) {
      setSelectedAccessDays([0, 1, 2, 3, 4, 5, 6]);
      setFieldValue("starting_access_day", "SUN");
      setFieldValue("ending_access_day", "SAT");
      setFieldValue("starting_access_time", "00:00");
      setFieldValue("ending_access_time", "23:59");
    } else {
      setSelectedAccessDays([]);
      setFieldValue("starting_access_day", "");
      setFieldValue("ending_access_day", "");
      setFieldValue("starting_access_time", "");
      setFieldValue("ending_access_time", "");
    }
    SetAccessTimingstoggle(!accesstimingstoggle);
  };

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting, values, resetForm }) => (
          <Form className="">
            <div className="d-flex col-md-12 gap-5">
              <div className="col">
                <div className="">
                  <label className="form-label a-font mb-0" htmlFor="name">Name</label>
                  <div className="d-flex form-input gap-2 align-items-center ps-2" style={{borderRadius:'8px'}}>
                    <MdDriveFileRenameOutline size={20} />
                    <Field
                      type="text"
                      name="name"
                      className="form-input custom-placeholder"
                      placeholder="storage facility name..."
                      style={{width:'100%',height:'90%',border:'none',borderTopRightRadius:'8px',borderBottomRightRadius:'8px'}}                      
                    />
                  </div>
                  <ErrorMessage name="name" component="div" className="text-danger" />
                </div>

                <div className="mt-3">
                  <label className="form-label a-font mb-0" htmlFor="location">Location</label>
                  <div className="d-flex form-input gap-2 align-items-center ps-2" style={{borderRadius:'8px'}}>
                    <MdLocationCity size={20} />
                    <Field name="location" type="text" className=""
                      style={{width:'100%',height:'90%',border:'none',borderTopRightRadius:'8px',borderBottomRightRadius:'8px'}}>
                      {({ field, form }) => (
                        <div className="" style={{width:'100%'}}>
                          <AddUnitLocation
                            id="location"
                            getLocationData={(data) => getLocationData(data, field.name, form.setFieldValue)}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <ErrorMessage name="location" component="div" className="text-danger" />
                </div>

                <div className="mt-3">
                  <label className="form-label a-font mb-0" htmlFor="address">Address</label>
                  <div className="d-flex form-input gap-2 align-items-center ps-2" style={{borderRadius:'8px'}}>
                    <MdDriveFileRenameOutline size={20} />
                    <Field
                      type="text"
                      name="address"
                      className="form-input custom-placeholder"
                      placeholder="storage facility address ..."
                      style={{width:'100%',height:'90%',border:'none',borderTopRightRadius:'8px',borderBottomRightRadius:'8px'}}
                    />
                  </div>
                  <ErrorMessage name="address" component="div" className="text-danger" />
                </div>

                <div className="d-flex gap-3 mt-4">
                  <div className="col">
                    <div
                      className="d-flex px-3 align-items-center justify-content-between"
                      style={{
                        backgroundColor: '#FAFBFD',
                        border: '1px solid #FFCC00',
                        cursor: 'pointer',
                        maxWidth: '239px',
                        borderRadius: '8px',
                        height: '46px',
                      }}
                      onClick={() => setShowModal(true)}
                    >
                      <HiClock />
                      <span className="n-font" style={{ fontSize: '14px', fontWeight: 500, color: '#323232' }}>
                        Select Work Hours
                      </span>
                      <GoChevronDown />
                    </div>

                    <Modal show={showModal} onHide={() => setShowModal(false)} contentClassName="custom-modal-width">
                      <Modal.Header closeButton>
                        <p className="mb-1 mt-2 a-font" style={{ fontSize: '30px', fontWeight: 700, color: '#202224' }}>
                          Select Working Hours
                        </p>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between mb-4 gap-2">
                          <p className="a-font mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#202224' }}>
                            Days
                          </p>
                          <div className="d-flex align-items-center">
                          {allDays.map((day, index) => (
                            <Field name="starting_work_day" key={index}>
                              {({ form }) => (
                                <div
                                  onClick={() => toggleDaySelection(index, form.setFieldValue)}
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    width: '38px',
                                    height: '38px',
                                    borderRadius: '50%',
                                    backgroundColor: selectedDays.includes(index) ? '#FFD700' : '#D3D3D3',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    margin: '0 5px',
                                    color: '#000',
                                  }}
                                >
                                  {day[0]}
                                </div>
                              )}
                            </Field>
                          ))}
                          </div>
                          <div className="d-flex align-items-center"
                            style={{
                              width: '0.5px',
                              backgroundColor: '#000000',
                              height: '32px',
                              margin: '0 3px'
                            }}
                          ></div>
                          <Field name="toggle">
                            {({ form }) => (
                              <div className="d-flex align-items-center gap-2">
                                <p className="a-font mb-0" style={{fontSize:'15px', fontWeight:500, color:'#000034'}}>24/7?</p>
                                {timingstoggle===true ? 
                                  <IoToggle size={35} color="#ffcc00" onClick={() => handleToggle(form.setFieldValue)}></IoToggle>
                                  :
                                  <PiToggleLeftFill size={35} color="#E2E8F0" onClick={() => handleToggle(form.setFieldValue)} /> 
                                }
                              </div>
                            )}
                          </Field>
                        </div>

                        <div>
                          <div className="d-flex align-items-center justify-content-between gap-3"
                            style={{
                              border: '1px solid #ddd',
                              padding: '10px',
                              borderRadius: '10px',
                            }}
                          >
                            <div>
                              <p className="mb-0" style={{ fontWeight: '600', width:'100px'}}>
                                {values.starting_work_day && values.ending_work_day ? 
                                  `${allDaysFull[daysMap.indexOf(values.starting_work_day)]} - ${allDaysFull[daysMap.indexOf(values.ending_work_day)]}` 
                                  : 'Select days'}
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <Field
                                type="time"
                                name="starting_work_time"
                                className="form-time-selection a-font px-3 p-1"
                                style={{ borderRadius: '8px' }}
                              />
                              <span>to</span>
                              <Field
                                type="time"
                                name="ending_work_time"
                                className="form-time-selection a-font px-3 p-1"
                                style={{ borderRadius: '8px' }}
                              />
                            </div>
                          </div>
                        </div>

                        <p className="a-font mb-0 mt-3" style={{ fontSize: '14px', fontWeight: 600, color: '#202224' }}>
                          Note: Days will be selected as a range, and the same time will be applicable for all days.
                          <ErrorMessage name="starting_work_time" component="div" className="text-danger" />
                          <ErrorMessage name="ending_work_time" component="div" className="text-danger" />
                        </p>

                        <Modal.Footer>
                          <button
                            type="button"
                            className="p-1 px-3 d-flex gap-2 align-items-center"
                            style={{
                              backgroundColor: '#F1F4F9',
                              borderRadius: '6px',
                              border: 'none',
                              width: '96px',
                            }}
                            onClick={() => setShowModal(false)}
                          >
                            <RxCross1 color="black" size={12} />
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="p-1 px-3 d-flex gap-2 align-items-center"
                            style={{
                              backgroundColor: '#FFCC00',
                              borderRadius: '6px',
                              border: 'none',
                              width: '96px',
                            }}
                            onClick={() => {
                              if (!values.starting_work_day || !values.ending_work_day || 
                                  !values.starting_work_time || !values.ending_work_time) {
                                toast.error("Please select all required fields");
                                return;
                              }
                              setShowModal(false);
                            }}
                          >
                            <IoCheckmark size={12} /> Save
                          </button>
                        </Modal.Footer>
                      </Modal.Body>
                    </Modal>
                  </div>
                  
                  <div className="col">
                    <div
                      className="d-flex px-3 align-items-center justify-content-between"
                      style={{
                        backgroundColor: '#FAFBFD',
                        border: '1px solid #FFCC00',
                        cursor: 'pointer',
                        maxWidth: '239px',
                        borderRadius: '8px',
                        height: '46px',
                      }}
                      onClick={() => setShowModal2(true)}
                    >
                      <HiClock />
                      <span className="n-font" style={{ fontSize: '14px', fontWeight: 500, color: '#323232' }}>
                        Select Access Hours
                      </span>
                      <GoChevronDown />
                    </div>

                    <Modal show={showModal2} onHide={() => setShowModal2(false)} contentClassName="custom-modal-width">
                      <Modal.Header closeButton>
                        <p className="mb-1 mt-2 a-font" style={{ fontSize: '30px', fontWeight: 700, color: '#202224' }}>
                          Select Access Hours
                        </p>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex align-items-center justify-content-between mb-4 gap-2">
                          <p className="a-font mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#202224' }}>
                            Days
                          </p>
                          <div className="d-flex align-items-center">
                          {allDays.map((day, index) => (
                          <Field name="starting_access_day" key={index}>
                            {({ form }) => (
                              <div
                                onClick={() => toggleaccessDaySelection(index, form.setFieldValue)}
                                className="d-flex align-items-center justify-content-center"
                                style={{
                                  width: '38px',
                                  height: '38px',
                                  borderRadius: '50%',
                                  backgroundColor: selectedAccessDays.includes(index) ? '#FFD700' : '#D3D3D3',
                                  fontWeight: 'bold',
                                  cursor: 'pointer',
                                  margin: '0 5px',
                                  color: '#000',
                                }}
                              >
                                {day[0]}
                              </div>
                            )}
                          </Field>
                        ))}
                          </div>
                          <div className="d-flex align-items-center"
                            style={{
                              width: '0.5px',
                              backgroundColor: '#000000',
                              height: '32px',
                              margin: '0 3px'
                            }}
                          ></div>
                          <Field name="toggle3">
                            {({ form }) => (
                              <div className="d-flex align-items-center gap-2">
                                <p className="a-font mb-0" style={{fontSize:'15px', fontWeight:500, color:'#000034'}}>24/7?</p>
                                {accesstimingstoggle===true ? 
                                  <IoToggle size={35} color="#ffcc00" onClick={() => handleToggleaccess(form.setFieldValue)}></IoToggle>
                                  :
                                  <PiToggleLeftFill size={35} color="#E2E8F0" onClick={() => handleToggleaccess(form.setFieldValue)} /> 
                                }
                              </div>
                            )}
                          </Field>
                        </div>

                        <div>
                          <div className="d-flex align-items-center justify-content-between gap-3"
                            style={{
                              border: '1px solid #ddd',
                              padding: '10px',
                              borderRadius: '10px',
                            }}
                          >
                            <div>
                              <p className="mb-0" style={{ fontWeight: '600', width:'100px'}}>
                                {values.starting_access_day && values.ending_access_day ? 
                                  `${allDaysFull[daysMap.indexOf(values.starting_access_day)]} - ${allDaysFull[daysMap.indexOf(values.ending_access_day)]}` 
                                  : 'Select days'}
                              </p>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <Field
                                type="time"
                                name="starting_access_time"
                                className="form-time-selection a-font px-3 p-1"
                                style={{ borderRadius: '8px' }}
                              />
                              <span>to</span>
                              <Field
                                type="time"
                                name="ending_access_time"
                                className="form-time-selection a-font px-3 p-1"
                                style={{ borderRadius: '8px' }}
                              />
                            </div>
                          </div>
                        </div>

                        <p className="a-font mb-0 mt-3" style={{ fontSize: '14px', fontWeight: 600, color: '#202224' }}>
                          Note: Days will be selected as a range, and the same time will be applicable for all days.
                          <ErrorMessage name="starting_access_time" component="div" className="text-danger" />
                          <ErrorMessage name="ending_access_time" component="div" className="text-danger" />
                        </p>

                        <Modal.Footer>
                          <button
                            type="button"
                            className="p-1 px-3 d-flex gap-2 align-items-center"
                            style={{
                              backgroundColor: '#F1F4F9',
                              borderRadius: '6px',
                              border: 'none',
                              width: '96px',
                            }}
                            onClick={() => setShowModal2(false)}
                          >
                            <RxCross1 color="black" size={12} />
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="p-1 px-3 d-flex gap-2 align-items-center"
                            style={{
                              backgroundColor: '#FFCC00',
                              borderRadius: '6px',
                              border: 'none',
                              width: '96px',
                            }}
                            onClick={() => {
                              if (!values.starting_access_day || !values.ending_access_day || 
                                  !values.starting_access_time || !values.ending_access_time) {
                                toast.error("Please select all required fields");
                                return;
                              }
                              setShowModal2(false);
                            }}
                          >
                            <IoCheckmark size={12} /> Save
                          </button>
                        </Modal.Footer>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>

                <div className="mt-3">
                  <label className="a-font mb-0 form-label" htmlFor="description">Description (Optional)</label>
                  <div className="">
                    <Field
                      name="description"
                      as='textarea'
                      className=""
                      placeholder="Add a description..."
                      style={{
                        width: '100%',
                        height: '300px',
                        border: '1px solid #D8D8D8',
                        borderRadius: '8px',
                        backgroundColor: '#FAFCFF',
                        padding: '10px',
                        textAlign: 'left', 
                        resize: 'none',     
                        direction: 'ltr',   
                      }}
                    />
                  </div>
                </div>
              </div>
              
              <div className="col">
                <AddUnitMap mapData={{
                  properties: {
                    lat: 40.7127281,
                    lon: -74.0060152,
                    formatted: "New York, NY, United States of America",
                  },
                }} />

                <FieldArray name="features">
                  {({ push, remove }) => (
                    <div className="mt-3">
                      <p className="a-font" style={{ fontSize: '16px', fontWeight: 500 }}>
                        Select Features
                      </p>
                      <div className="d-flex flex-column gap-3">
                        {['Climate Controlled', 'Vehicle Storage', 'Commercial Storage', 'Outdoor Storage'].map(
                          (featureText, index) => {
                            const isSelected = values.features.some(f => f.features_text === featureText);

                            return (
                              <span
                                key={index}
                                className={`d-flex p-1 ps-1 gap-2 n-font featurespan ${
                                  isSelected ? 'selected' : ''
                                }`}
                                onClick={() => {
                                  if (isSelected) {
                                    const featureIndex = values.features.findIndex(
                                      f => f.features_text === featureText
                                    );
                                    remove(featureIndex);
                                  } else {
                                    push({ features_text: featureText });
                                  }
                                }}
                                style={{ cursor: 'pointer', position: 'relative', transition: 'all 0.3s' }}
                              >
                                <span>
                                  {isSelected ? <IoCheckmark/> : <FiPlus/>}
                                </span>
                                <span className="remove-icon" style={{ display: 'none' }}>
                                  <FiMinus />
                                </span>
                                {featureText}
                              </span>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </FieldArray>
              </div>
            </div>

            <FieldArray name="facility_images">
              {({ push, remove }) => (
                <div className="mt-4">
                  <div
                    style={{
                      border: "2px solid #FFDF61",
                      boxShadow:'0px 0px 0px 4px #FFDF6140',
                      borderRadius: "32px",
                      padding: "24px",
                      textAlign: "center",
                      maxHeight:'170px',
                    }}
                  >
                    <div>
                      {!uploadedImages || uploadedImages.length === 0 ? (
                        <label htmlFor="upload-input" style={{ cursor: "pointer" }}>
                          <div
                            className="d-flex justify-content-center align-items-center mx-auto mb-3"
                            style={{
                              backgroundColor: "#F1F4F9",
                              width: "48px",
                              height: "48px",
                              borderRadius: "123px",
                            }}
                          >
                            <TbFileUpload size={25} />
                          </div>
                          <span
                            style={{
                              color: "#FFCC00", cursor: "pointer", fontSize: "16px", fontWeight: 600,
                            }}
                          >
                            Click here{" "}
                            <span style={{ color: "#475569", cursor: "pointer" }}>
                              to upload your file or drag.
                            </span>
                          </span>
                          <p
                            className="mt-2 mb-0"
                            style={{ fontSize: "14px", color: "#94A3B8" }}
                          >
                            Supported Format: SVG, JPG, PNG (10mb each)
                          </p>
                        </label>
                      ) : null}
                    </div>

                    <div className="d-flex align-items-center gap-3">
                      {uploadedImages.map((image, index) => (
                        <div key={index} className="d-flex imagehandling">
                          <div className="onhovereffect">
                            <img
                              src={image.image}
                              className="img-fluid rounded"
                              alt="storagePic"
                              style={{
                                width: 120,
                                height: 120,
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div className="hide">
                            <span 
                              className="px-3 p-1"
                              style={{backgroundColor:'#EF3826',fontSize:'9px', fontWeight:600,border:'1px solid #979797',borderRadius:'6px',color:'white'}}
                              type="button" onClick={() => removeImage(index)}>
                              - Remove
                            </span>
                          </div>
                        </div>
                      ))}
                      {uploadedImages != 0 ? (
                        <div className="flex-end">
                          <label htmlFor="upload-input" style={{ cursor: "pointer" }}>
                            <span
                              className="px-3 p-1 text-end"
                              style={{backgroundColor:'#EF3826',fontSize:'9px', fontWeight:600,border:'1px solid #979797',borderRadius:'6px',color:'white'}}
                              type="button">
                              + Add More
                            </span>
                          </label>
                        </div>
                      ) : null }
                    </div>
                  </div>
                  <input
                    id="upload-input"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    multiple
                    style={{ display: "none" }}
                  />
                  <ErrorMessage name="facility_images" component="div" className="text-danger" />
                </div>
              )}
            </FieldArray>

            <div className="d-flex flex-row-reverse gap-3 mt-5 mx-3">
              <button 
                className="p-1 px-3 d-flex gap-2 align-items-center"
                style={{
                  backgroundColor:'#F1F4F9',
                  borderRadius:'6px',
                  border:'none',
                  width:'96px',
                }}
                type="button"
                onClick={() => resetForm()}
              >
                <RxCross1 color="black" size={12}/>  
                Cancel
              </button>
              <button 
                className="p-1 px-3 d-flex gap-2 align-items-center"
                style={{
                  backgroundColor:'#FFCC00',
                  borderRadius:'6px',
                  border:'none',
                  width:'96px',
                }}
                type="submit"
                disabled={isSubmitting}
              >
                <IoCheckmark size={12}/> {isSubmitting ? "Submitting..." : " Save"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddUnitForm;