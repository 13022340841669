import React from 'react'
import { FaQuestion } from 'react-icons/fa'
import { Link } from "react-router-dom";

const SpaceTerms = () => {
  return (
    <div style={{backgroundColor:'#ffcc00'}}>
        <div className='mx-auto'>
            <div className='w-full align-items-center d-flex justify-content-center'>
                <img src='/images/mainlandingpic.jpg' alt='spaceterms'
                className='img-fluid mx-auto'
                style={{
                    objectFit:'cover',
                    width:'1180px',
                    height:'580px',
                    borderRadius:'30px',
                    marginTop:'120px',
                    marginBottom:'120px',
                }}
                ></img>
            </div>
            <div className='d-flex flex-column gap-3'>
            <p className='a-font landing-head blueTextColr'>Your Space, Your Terms</p>
            <p className='a-font landing-text2 blueTextColr'>(No Complex Fees or Hassles)</p>
            <p className='a-font landing-text2 blueTextColr' style={{fontWeight:700}}>Whether you’re:</p>
            </div>
        </div>
        <div className='d-flex gap-5 justify-content-center align-items-center mx-auto mt-5' style={{width:'70%'}}>
            <div className='d-flex flex-column gap-5'>
                <div style={{ textAlign: 'center' }}>
                    <div
                        className="flex items-center justify-center"
                        style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: '#0000343D',
                        margin: '0 auto',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        }}
                    >
                        <FaQuestion size={30} color="#000" />
                    </div>
                    <p className="a-font blueTextColr landing-text2" style={{ marginTop: '10px' }}>
                        Looking to monetize empty <br></br> space
                    </p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div
                        className="flex items-center justify-center"
                        style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: '#0000343D',
                        margin: '0 auto',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        }}
                    >
                        <FaQuestion size={30} color="#000" />
                    </div>
                    <p className="a-font blueTextColr landing-text2" style={{ marginTop: '10px' }}>
                        Tired of complicated agreements and long vacancies
                    </p>
                </div>
            </div>
            <div className='d-flex flex-column gap-5'>
            <div style={{ textAlign: 'center' }}>
                    <div
                        className="flex items-center justify-center"
                        style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: '#0000343D',
                        margin: '0 auto',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        }}
                    >
                        <FaQuestion size={30} color="#000" />
                    </div>
                    <p className="a-font blueTextColr landing-text2" style={{ marginTop: '10px' }}>
                        Desiring a simple way to connect with reliable renters
                    </p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div
                        className="flex items-center justify-center"
                        style={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                        backgroundColor: '#0000343D',
                        margin: '0 auto',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center',
                        }}
                    >
                        <FaQuestion size={30} color="#000" />
                    </div>
                    <p className="a-font blueTextColr landing-text2" style={{ marginTop: '10px' }}>
                        Interested in earning passive income effortlessly
                    </p>
                </div>
            </div>
        </div>

        <div className='d-flex flex-column gap-2 justify-content-center mx-auto align-items-center mt-5' 
            style={{width:'80%'}}>
            <p className='blueTextColr a-font landing-text2'>We've got a secure, easy-to-navigate with single dashboard platform to turn your empty space into income on your own terms. List your storage units for free today, and let our AI handle bookings, security, and customer management.</p>
            <div>
            <Link to={'/sign-up-storage-provider'}>
            <button
            className='a-font landing-text1 text-white p-2 px-3 mt-3'
            style={{
                backgroundColor:'#000034',
                border:'4px solid #000034',
                borderRadius:'10px',
                fontWeight:700,
            }}
            >List Your Storage Unit</button>
            </Link>
            </div>
            <p className='landing-head a-font blueTextColr text-uppercase'>For Free</p>
        </div>

    </div>
  )
}

export default SpaceTerms